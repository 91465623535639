<template>
  <div class="main-page">
    <el-form :model="ShopForm" class="form small-form">
      <div class="filter-item">
        <el-form-item label="logo：" prop="logo" style="margin-left: 40px">
          <image-wall ratio="160:160" v-model="ShopForm.logo" :width="80" :height="80">
            <p slot="info" class="img-tips">
              <span class="el-icon-info" />建议尺寸为300*300
            </p>
          </image-wall>
          <el-input v-model="ShopForm.logo" v-show="false" />
        </el-form-item>
      </div>

      <div class="filter-item">
        <el-form-item label="店铺名称：" prop="name" required>
          <el-input
            type="text"
            v-model="ShopForm.name"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
      </div>

      <div class="filter-item">
        <el-form-item label="店铺类型：" prop="type" required>
          <el-select
            v-model="ShopForm.type"
            placeholder="请选择"
            @change="selectChange"
          >
            <el-option
              v-for="item in TypeArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-button
            type="primary"
            @click="manageType"
            style="margin-left: 20px"
            >管理</el-button
          >
        </el-form-item>
      </div>

      <el-form-item label="营业时间：" prop="activityJoinTime" required>
        <div class="filter-item outer">
          <div class="filter-item border">
            <DatePicker
              :ShopForm="ShopForm"
              v-for="(time, index) in opening_times"
              :key="index"
              @selectTime="selectTime"
              :index="index"
              :opening_times="opening_times"
            ></DatePicker>
          </div>
          <el-button type="primary" @click="addOpenTime">新增</el-button>
        </div>
      </el-form-item>
      <div class="filter-item">
        <el-form-item label="店铺地址：" prop="address" required>
          <el-button
            style="max-width: 295px"
            class="company-address"
            @click="handleAddressSelect"
          >
            {{ ShopForm.address || '请选择企业地址' }}</el-button
          >
          <el-input v-model="ShopForm.address" v-if="false"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item" style="margin-left: 10px">
        <el-form-item label="联系电话：" prop="phoneNum">
          <el-input
            type="text"
            v-model="ShopForm.phoneNum"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
      </div>
      <div class="filter-item" style="margin-left: 10px">
        <el-form-item label="活动细则：" prop="activeMsg">
          <el-input
            type="textarea"
            v-model="ShopForm.activeMsg"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
    <div class="bottom">
      <el-button type="normal">存为草稿</el-button>
      <el-button type="primary" @click="publish">发布</el-button>
    </div>

    <AddressSelector
      v-model="openAddressSelector"
      @change="handleChangeAddress"
      :detailForm="addressForm"
    />
    <TypeSelector
      :openDialog="openStatusSelect"
      @closeDialog="closeDialog"
    ></TypeSelector>
  </div>
</template>

<script>
import ImageWall from '@/modules/common/components/SingleMediaWall.vue';
import DatePicker from '../../components/ShopList/DatePicker';
import AddressSelector from '../../../common/components/AddressSelector.vue';
import TypeSelector from '../../components/ShopList/TypeSelector';
import { shopType, shopAdd, shopDetail } from '../../api/shop-list/list';

export default {
  name: 'addMember',
  data() {
    return {
      ShopForm: {
        logo: '', //店铺logo
        name: '', //店铺名称
        type: '', //店铺类型
        shop_end_time: '', //营业开始时间
        shop_start_time: '', //营业结束时间
        address: '', //店铺地址
        phoneNum: '', //联系电话
        activeMsg: '', //活动细则
        lat: '',
        lng: '',
        activityJoinTime: [],
      },
      TypeArr: [],
      openAddressSelector: false,
      // 活动地址信息
      addressForm: {
        lat: '',
        lng: '',
        address: '',
      },
      opening_times: [[null, null]],
      select_type: [],
      openStatusSelect: false,
    };
  },
  created() {
    // 店铺类型
    shopType().then((res) => {
      this.TypeArr = res.data;
    });

    shopDetail({ id: this.$route.params.id }).then((res) => {
      console.log(res);
      this.ShopForm.logo = res.data.logo;
      this.ShopForm.name = res.data.name;
      this.ShopForm.address = res.data.address;
      this.ShopForm.lat = res.data.lat;
      this.ShopForm.lng = res.data.lng;
      // this.ShopForm.activityJoinTime = [new Date(1662998400000), new Date(1663603200000)]
      res.data.opening_times.forEach((el, index) => {
        // console.log(el,index);
        let star = el[0] * 1000
        let end = el[1] * 1000
         console.log(new Date(star));
        // this.ShopForm.activityJoinTime[index][0] = new Date(star);
        // this.ShopForm.activityJoinTime[index][1] = new Date(end);
      });
      this.ShopForm.activeMsg = res.data.content;

      this.opening_times.length = res.data.opening_times.length;
    });
  },
  methods: {
    //选择活动地址
    handleAddressSelect() {
      this.addressForm = {
        lat: this.ShopForm.lat,
        lng: this.ShopForm.lng,
        address: this.ShopForm.address,
      };
      this.openAddressSelector = true;
    },
    handleChangeAddress(value) {
      this.ShopForm.lat = value.lat;
      this.ShopForm.lng = value.lng;
      this.ShopForm.address = value.address;
    },
    selectChange(val) {
      this.select_type = [];
      this.select_type.push(val);
    },
    // 发布
    publish() {
      let data = {
        id: '',
        logo: this.ShopForm.logo,
        name: this.ShopForm.name,
        category_ids: this.select_type,
        opening_times: this.opening_times,
        address: this.ShopForm.address,
        lat: this.ShopForm.lat,
        lng: this.ShopForm.lng,
        concact: this.ShopForm.phoneNum,
        content: this.ShopForm.activeMsg,
      };
      shopAdd(data).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: res.msg,
            type: 'success',
          });
          this.$router.push({ name: 'CycRightsShopList' });
        } else {
          this.$message({
            message: res.data,
            type: 'error',
          });
        }
      });
    },
    // 管理
    manageType() {
      this.openStatusSelect = true;
    },
    closeDialog(val) {
      this.openStatusSelect = val;
    },
    // 新增
    addOpenTime() {
      this.opening_times.push([null, null]);
    },
    selectTime(index, star, end) {
      this.opening_times[index][0] = star;
      this.opening_times[index][1] = end;
    },
  },
  components: {
    ImageWall,
    AddressSelector,
    DatePicker,
    TypeSelector,
  },
};
</script>

<style scoped>
.bottom {
  text-align: center;
  width: 250px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.img-tips {
  margin-top: 8px;
  font-size: 12px;
  color: #c0c4cc;
  line-height: 15px;
}
.outer {
  display: flex;
}
.outer .el-button {
  height: 40px;
}
.border {
  width: 420px;
}
</style>